import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import "./styles/App.scss";
import AppRoute from "./AppRoute";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Space.ai</title>
      <meta name="description" content="This is the description for my page." />
    </Helmet>
    <Router>
      <AppRoute />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
