import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CampaignView = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const visitors = [
    { number: 3456789053, isActive: true, favoriteproducts: 22 },
    { number: 8765432173, isActive: false, favoriteproducts: 200 },
    { number: 5512345641, isActive: true, favoriteproducts: 19 },
    { number: 8877766665, isActive: false, favoriteproducts: 26 },
    { number: 6699988877, isActive: true, favoriteproducts: 10 },
    { number: 8965743591, isActive: true, favoriteproducts: 12 },
    { number: 9987445689, isActive: false, favoriteproducts: 2 },
    { number: 8901250044, isActive: false, favoriteproducts: 33 },
    { number: 8141668829, isActive: true, favoriteproducts: 33 },
    { number: 9773422922, isActive: false, favoriteproducts: 33 },
  ];
  console.log(visitors, "visitors");

  const sortedVisitors = visitors.sort(
    (a: any, b: any) => b.isActive - a.isActive
  );

  const filteredVisitors = sortedVisitors.filter((visitor) =>
    visitor.number.toString().includes(searchTerm)
  );

  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h2 className="fw-bold mb-2 mb-md-0">Campaign 1</h2>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end align-items-start gap-2 flex-column flex-md-row"
        >
          <button className="btn btn-sm uniform-submit-btn">
            Enable Kiosk Mode
          </button>
          <button
            className="btn btn-sm uniform-back-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={8} className="mb-3 mb-md-0 d-flex align-items-stretch">
          <div className="rounded w-100">
            <img
              src="/images/campaign_view_banner.jpg"
              className="rounded img-fluid h-100"
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
        </Col>
        <Col xs={12} md={4} className="d-flex align-items-stretch">
          <div className="border border-1 rounded text-center w-100 p-3 d-flex flex-column justify-content-center align-items-center qr-box">
            <img
              src="/images/demo_qr_code.png"
              className="mt-5 img-fluid"
              style={{ maxHeight: "100%", width: "auto", maxWidth: "75%" }}
              alt=""
            />
            <div className="d-flex justify-content-center align-items-center mt-4">
              <img src="/icon/qr_icon.svg" alt="" />
              <p className="mb-0 mx-2">Scan this QR code to get started</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 mb-4">
        <Col>
          <h2 className="fw-bold">Visitor List</h2>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <Form.Control
              type="text"
              placeholder="Search Visitor"
              aria-label="Search Visitor"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="btn btn-icon uniform-btn">
              <i className="bi bi-search"></i>
            </button>
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
        {filteredVisitors.map((visitor, index) => (
          <Col xs={12} key={index} className="mb-1">
            <Card
              className="campaign-list-card"
              onClick={() => navigate(`${1}/visitor-details`)}
            >
              <Card.Body className="py-3">
                <Card.Text className="m-0 fs-4 d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <b>{visitor.number}</b>
                  </div>
                  <div>
                    <span
                      className={`badge ${
                        visitor.isActive ? "badge-green" : "badge-red"
                      }`}
                    >
                      {visitor.isActive ? "Active" : "Inactive"}
                    </span>
                  </div>
                  <div>
                    <button className="btn btn-sm uniform-btn width-for-fvt-product-btn">
                      Favorite Products: {visitor.favoriteproducts}
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center mt-4 mb-5">
        <button className="btn btn-sm uniform-submit-btn">Load More</button>
      </div>
    </div>
  );
};

export default CampaignView;
