import { toast, ToastOptions } from "react-toastify";

const toastConfig: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const setToast = (type: string, message: string) => {
  switch (type) {
    case "error":
      toast.error(message, toastConfig);
      break;
    case "success":
      toast.success(message, toastConfig);
      break;
    default:
      toast(message, toastConfig);
      break;
  }
};
