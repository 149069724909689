import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const VisitorDetails = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const products = [
    {
      id: 1,
      title: "DR Rustica Foggy Smoke",
      size: '9.4 x 86.6"',
      imageUrl: "https://via.placeholder.com/150x250?text=Product+1",
    },
    {
      id: 2,
      title: "DR Rustica Natural Cotto",
      size: '9.4 x 86.6"',
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwGkhjQe4ja8GHvAgs8sJy59Z-y-uWZpz_uw&usqp=CAU",
    },
    {
      id: 3,
      title: "DR Linea Decor Moroccan",
      size: '9.4 x 86.6"',
      imageUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBJQeF5WofXECCFt4M-0ZAFWya_vNt1jz11Q&usqp=CAU",
    },
    {
      id: 4,
      title: "DR Matte Coquina Sand Ivory",
      size: '9.4 x 86.6"',
      imageUrl: "https://via.placeholder.com/150x250?text=Product+4",
    },
    {
      id: 5,
      title: "DR Carving Colour",
      size: '9.4 x 86.6"',
      imageUrl: "https://via.placeholder.com/150x250?text=Product+5",
    },
    {
      id: 6,
      title: "DR Carving Decor Autumn",
      size: '9.4 x 86.6"',
      imageUrl:
        "https://www.shutterstock.com/image-photo/open-road-leads-grand-tetons-600w-1776070577.jpg",
    },
    {
      id: 7,
      title: "DR Natural Rootwood Silver",
      size: '9.4 x 86.6"',
      imageUrl:
        "https://www.shutterstock.com/image-photo/empty-highway-asphalt-road-beautiful-260nw-1516762826.jpg",
    },
    {
      id: 8,
      title: "Endless Crackle Dyna Marble",
      size: '9.4 x 86.6"',
      imageUrl: "https://via.placeholder.com/150x250?text=Product+8",
    },
  ];

  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center d-flex flex-wrap">
        <Col xs={12} md={5}>
          <h2 className="fw-bold">Visitor Details</h2>
        </Col>
        <Col
          xs={12}
          md={7}
          className="d-flex justify-content-md-end align-items-center gap-2"
        >
          <button className="btn btn-sm uniform-submit-btn mb-md-0">
            Visual Kiosk
          </button>
          <button
            className="btn btn-sm uniform-edit-btn mb-md-0"
            onClick={() => handleModalShow()}
          >
            Edit
          </button>
          <button
            className="btn btn-sm uniform-back-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </Col>
      </Row>
      <div className="rounded p-3 mt-5 visitor-details-table">
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Email:
              </div>
              <div className="col-6 fw-bolder">gmail@test.com</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Mobile:
              </div>
              <div className="col-6 fw-bolder">8564598512</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Organization Id:
              </div>
              <div className="col-6 fw-bolder">1</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Tracking Code:
              </div>
              <div className="col-6 fw-bolder">dssd9</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Vendor Code:
              </div>
              <div className="col-6 fw-bolder">dssd9</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                History Padding:
              </div>
              <div className="col-6 fw-bolder">0</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Blocked:
              </div>
              <div className="col-6 fw-bolder">0</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Top Window:
              </div>
              <div className="col-6 fw-bolder">0</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Created At:
              </div>
              <div className="col-6 fw-bolder">15-Jul-2024</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row mb-4">
              <div className="col-6 visitor-details-label fw-bolder">
                Updated At:
              </div>
              <div className="col-6 fw-bolder">15-Jul-2024</div>
            </div>
          </div>
        </div>
      </div>
      <Row className="align-items-center mb-4 mt-5">
        <Col>
          <h2 className="fw-bold">Favorite Products</h2>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <Form.Control
              type="text"
              placeholder="Search Products"
              aria-label="Search Products"
            />
            <button className="btn btn-icon uniform-btn">
              <i className="bi bi-search"></i>
            </button>
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
        {products.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <div className="border rounded d-flex flex-column favorite-products-perent-div">
              <div className="favorite-products-div d-flex justify-content-center">
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  className="favorite-products-img"
                />
              </div>
              <h6 className="fw-bold mb-1 text-center">{product.title}</h6>
              <p className="text-secondary text-center">{product.size}</p>
            </div>
          </Col>
        ))}
      </Row>

      <div className="d-flex justify-content-center mt-4 mb-5">
        <button className="btn btn-sm uniform-submit-btn">Load More</button>
      </div>
      <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
        <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
          <Modal.Title>
            <h2>
              <strong>Visitor Details</strong>
            </h2>
          </Modal.Title>
          <img
            src="/icon/closeicon.svg"
            alt="close"
            className="custom-close-icon mb-4"
            onClick={handleModalClose}
          />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="email" className="fw-semibold lebal-font">
                    Email*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="mobile" className="fw-semibold lebal-font">
                    Mobile*
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="Enter Your Mobile Number"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="organization_id"
                    className="fw-semibold lebal-font"
                  >
                    Organization Id*
                  </label>
                  <input
                    type="text"
                    id="organization_id"
                    name="organization_id"
                    placeholder="Enter Organization Id"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="tracking_code"
                    className="fw-semibold lebal-font"
                  >
                    Tracking Code*
                  </label>
                  <input
                    type="text"
                    id="tracking_code"
                    name="tracking_code"
                    placeholder="Enter Tracking Code"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="vendor_code"
                    className="fw-semibold lebal-font"
                  >
                    Vendor Code*
                  </label>
                  <input
                    type="text"
                    id="vendor_code"
                    name="vendor_code"
                    placeholder="Enter Vendor Code"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="history_padding"
                    className="fw-semibold lebal-font"
                  >
                    History Padding*
                  </label>
                  <input
                    type="text"
                    id="history_padding"
                    name="history_padding"
                    placeholder="Enter History Padding"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="blocked" className="fw-semibold lebal-font">
                    Blocked*
                  </label>
                  <input
                    type="text"
                    id="blocked"
                    name="blocked"
                    placeholder="Enter Blocked status"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="top_window"
                    className="fw-semibold lebal-font"
                  >
                    Top Window*
                  </label>
                  <input
                    type="text"
                    id="top_window"
                    name="top_window"
                    placeholder="Enter Top Window"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="created_at"
                    className="fw-semibold lebal-font"
                  >
                    Created At*
                  </label>
                  <input
                    type="text"
                    id="created_at"
                    name="created_at"
                    placeholder="Enter Created At date"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="updated_at"
                    className="fw-semibold lebal-font"
                  >
                    Updated At*
                  </label>
                  <input
                    type="text"
                    id="updated_at"
                    name="updated_at"
                    placeholder="Enter Updated At date"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="btn btn-sm uniform-submit-btn"
            onClick={handleModalClose}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisitorDetails;
