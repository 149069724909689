import React from "react";
import { Card, Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Campaign: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center mb-4 mt-1">
        <Col>
          <h1>
            <strong>Campaign List</strong>
          </h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <Form.Control
              type="text"
              placeholder="Search campaign"
              aria-label="Search campaign"
            />
            <button className="btn btn-icon uniform-btn">
              <i className="bi bi-search"></i>
            </button>
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((campaign, index) => (
          <Col xs={12} key={index}>
            <Card
              className="campaign-list-card"
              onClick={() => navigate(`${campaign}/campaign-view`)}
            >
              <Card.Body className="py-3">
                <Card.Text className="m-0 fs-4">
                  <b>Campaign {campaign}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex justify-content-center mt-4 mb-5">
        <button className="btn btn-sm uniform-submit-btn">Load More</button>
      </div>
    </div>
  );
};

export default Campaign;
