import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { getToken } from "../../utils/authUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { setToast } from "./Toast";

interface CatalogListModalProps {
  showCatalogModal: boolean;
  closeCatalogModal: () => void;
}
interface Catalog {
  id: number;
  title: string;
  catalog_url: string;
}

const CatalogListModal: React.FC<CatalogListModalProps> = ({
  showCatalogModal,
  closeCatalogModal,
}) => {
  const [catalogData, setCatalogData] = useState<Catalog[]>([]);
  const [catalogToDelete, setCatalogToDelete] = useState<number | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [viewingPdf, setViewingPdf] = useState<number | null>(null);

  useEffect(() => {
    fetchCatalog();
  }, [showCatalogModal]);

  const handleDownloadPDF = async (catalogId: number) => {
    setLoadingPdf(true);
    setViewingPdf(catalogId);
    try {
      const apiUrlCatalog = `${process.env.REACT_APP_API_URL}api/generate_pdf/${catalogId}`;
      const response = await axios.get(apiUrlCatalog, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response.data) {
        const anchor = document.createElement("a");
        anchor.href = response.data.pdf_path;
        anchor.download = "Download Pdf";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        console.error("Failed to download PDF");
      }
    } catch (error) {
      closeCatalogModal();
      setToast("error", "Something went wrong. Please try again later.");
    } finally {
      setLoadingPdf(false);
      setViewingPdf(null);
    }
  };

  const fetchCatalog = async () => {
    const userToken = getToken();
    try {
      if (!userToken) {
        return;
      }
      const apiUrlCatalog = `${process.env.REACT_APP_API_URL}api/visitor/catalogs`;
      const response = await axios.get(apiUrlCatalog, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setCatalogData(response.data.data);
    } catch (error) {
      console.error("Error fetching Catalogs:", error);
    }
  };

  const handleDeleteCatalog = (catalogId: number) => {
    setCatalogToDelete(catalogId);
    setShowConfirmationModal(true);
  };

  const confirmDeleteCatalog = async () => {
    try {
      const apiUrlCatalog = `${process.env.REACT_APP_API_URL}api/catalogs/${catalogToDelete}`;
      const response = await axios.delete(apiUrlCatalog, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (response.status === 200) {
        setCatalogData((prevState) =>
          prevState.filter((catalog) => catalog.id !== catalogToDelete)
        );
        closeCatalogModal();
        setToast("success", "Catalog deleted successfully");
      } else {
        setToast("error", "Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error deleting Catalog:", error);
      setToast("error", "Something went wrong. Please try again later.");
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const cancelDeleteCatalog = () => {
    setCatalogToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div>
      <Modal
        centered
        show={showConfirmationModal}
        onHide={cancelDeleteCatalog}
        dialogClassName="confirmation-modal"
      >
        <Modal.Body className="text-center d-flex flex-column">
          <h5>Confirm Deletion</h5>
          <p>Are you sure you want to delete this catalog? </p>
          <div className="border-0 mt-3 d-flex justify-content-around">
            <button
              className="btn btn-sm uniform-btn"
              style={{ width: "45%" }}
              onClick={cancelDeleteCatalog}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm uniform-delete-btn"
              style={{ width: "45%" }}
              onClick={confirmDeleteCatalog}
            >
              <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faTrash} />
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showCatalogModal}
        onHide={closeCatalogModal}
        dialogClassName={showConfirmationModal ? "blur-modal" : ""}
        style={{}}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Catalog list</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            width: "100%",
            margin: "0 auto",
            backgroundColor: "#F0F0F5",
          }}
        >
          {catalogData.length === 0 ? (
            <p className="text-center">No Content Available.</p>
          ) : (
            <div>
              {catalogData.map((catalog) => (
                <div
                  key={catalog.id}
                  className="card mb-4 border-0"
                  style={{
                    backgroundColor: "#ffffff",
                    paddingTop: "11px",
                    width: "90%",
                    margin: "0 auto",
                  }}
                >
                  <div className="card-body d-flex align-items-center justify-content-between mx-1">
                    <div>
                      <p>
                        {" "}
                        <strong>{catalog.title}</strong>
                      </p>
                    </div>
                    <div>
                      <div>
                        <button
                          className="bg-transparent border-0 text-primary mb-2"
                          onClick={() => handleDownloadPDF(catalog.id)}
                        >
                          {viewingPdf === catalog.id && loadingPdf ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              variant="black"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </button>
                        <button
                          className="bg-transparent border-0 text-danger mb-2"
                          onClick={() => handleDeleteCatalog(catalog.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CatalogListModal;
