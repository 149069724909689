import React from "react";
import { Route, Routes } from "react-router-dom";
import SigninKiosk from "./SigninKiosk";
import KioskNavbar from "./KioskNavBar";
import Campaign from "./Campaign";
import CampaignView from "./CampaignView";
import VisitorDetails from "./VisitorDetails";
import NotFound from "../App/Components/NotFound";

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => (
  <div>
    <KioskNavbar />
    {children}
  </div>
);

const KiosksRoute: React.FC = () => {
  return (
    <div className="kiosk-body">
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout>
              <Campaign />
            </AppLayout>
          }
        />
        <Route path="/signin" element={<SigninKiosk />} />
        <Route
          path=":id/campaign-view"
          element={
            <AppLayout>
              <CampaignView />
            </AppLayout>
          }
        />
        <Route
          path=":id/campaign-view/:id/visitor-details"
          element={
            <AppLayout>
              <VisitorDetails />
            </AppLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default KiosksRoute;
