import React, { useState, useRef, useEffect } from "react";
import { Row, Col, ProgressBar, Modal, Button } from "react-bootstrap";
import axios, { AxiosProgressEvent } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../utils/authUtils";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { setToast } from "./Toast";
import { BottomSheet } from "react-spring-bottom-sheet";

interface ChangeRoomProps {
  showChangeRoomModal: boolean;
  toggleChangeRoomModal: () => void;
}

const ChangeRoom: React.FC<ChangeRoomProps> = ({
  showChangeRoomModal,
  toggleChangeRoomModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedImages, setSelectedImages] = useState<
    { id: number; image: string }[]
  >([]);
  const [isImageSelectedForDeletion, setIsImageSelectedForDeletion] =
    useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [displayedImagesCount, setDisplayedImagesCount] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState(false);
  const controllerRef = useRef<AbortController>(new AbortController());
  const [imageToDeleteIndex, setImageToDeleteIndex] = useState<number | null>(
    null
  ); // Index of image to delete
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // State for delete confirmation modal

  useEffect(() => {
    const token = getToken();
    setUserToken(token);
  }, [showChangeRoomModal, displayedImagesCount]);

  useEffect(() => {
    if (isImageSelected && uploadProgress < 100) {
      const interval = setInterval(() => {
        setUploadProgress((prevProgress) => Math.min(prevProgress + 5, 100));
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [isImageSelected, uploadProgress]);

  const getUploadImage = async () => {
    if (!userToken || !selectedImageFile) {
      console.error(
        "User token is null or no image selected. Unable to make the request."
      );
      return;
    }

    const uploadController = new AbortController();
    controllerRef.current = uploadController;

    const formData = new FormData();
    formData.append("name", "Room 1");
    formData.append("image", selectedImageFile);

    try {
      setUploading(true);
      const apiUrl = (process.env.REACT_APP_API_URL as string) + "api/add_room";
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / (progressEvent.total || 1)) * 100
          );
          setUploadProgress(progress);
        },
        signal: uploadController.signal,
      });
      setUploading(false);
      setRoomQueryParams(response.data.data.id);
      fetchImageDataFromApi(userToken, displayedImagesCount);
      handleCloseModal();
    } catch (error: any) {
      if (error.code === "ERR_CANCELED") {
        setToast("error", `Upload ${error.message}`);
      } else {
        setToast("error", "Image upload failed!");
      }
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };

  const setRoomQueryParams = (id: string) => {
    Cookies.set("room_id", id);
    const newSearch = queryParams.toString();
    handleCloseModal();
    navigate({
      pathname: location.pathname,
      search: newSearch,
    });
    window.location.reload();
  };

  useEffect(() => {
    if (userToken) {
      fetchImageDataFromApi(userToken, displayedImagesCount);
    }
  }, [userToken]);

  const fetchImageDataFromApi = async (token: string, count: number) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/public_rooms?page=${count}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalPage(response.data.meta.last_page);

      const imageUrls = response.data.data.map((item: any) => ({
        id: item.id,
        image: item.image,
      }));
      setSelectedImages((prevImages) => [...imageUrls, ...prevImages]);
    } catch (error) {
      console.error("Error fetching image data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsImageSelected(false);
    setUploadProgress(0);
    toggleChangeRoomModal();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedImageFile = files[0];
      setSelectedImageFile(selectedImageFile);
      setIsImageSelected(true);
      setUploading(true);
      setUploadProgress(0);
    } else {
      setIsImageSelected(false);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlecancelUpload = (e: any) => {
    if (uploading) {
      controllerRef.current.abort();
      setUploading(false);
      setUploadProgress(0);
      setIsImageSelected(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    return () => {
      controllerRef.current.abort();
    };
  }, []);

  useEffect(() => {
    if (userToken) {
      getUploadImage();
    }
  }, [selectedImageFile]);

  const handleDeleteImageConfirmation = (index: number) => {
    setImageToDeleteIndex(index);
    setDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (imageToDeleteIndex !== null) {
      await handleDeleteImage(imageToDeleteIndex);
      setDeleteConfirmation(false);
    }
  };

  const handleDeleteImage = async (index: number) => {
    try {
      const imageToDelete = selectedImages[index];
      if (!imageToDelete) {
        return;
      }
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) +
        `api/public_rooms/${imageToDelete.id}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
      } else {
        console.error("Failed to delete image:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const loadMoreImages = async () => {
    if (displayedImagesCount < totalPage) {
      // Check if there are more pages to load
      try {
        const nextPage = displayedImagesCount + 1;
        const token = getToken();
        const apiUrl = `${process.env.REACT_APP_API_URL}api/public_rooms?page=${nextPage}`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const newImages = response.data.data.map((item: any) => ({
          id: item.id,
          image: item.image,
        }));

        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        setDisplayedImagesCount(nextPage);
      } catch (error) {
        console.error("Error loading more images:", error);
      }
    }
  };

  return (
    <div>
      <BottomSheet
        open={showChangeRoomModal && !deleteConfirmation}
        onDismiss={handleCloseModal}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 100]}
      >
        <div className="card m-2 border-0">
          <div className="fw-bold fs-5 text-center border-0 mb-2 meet">
            Try Products In Your Room
          </div>
          <div className="card-body">
            <div className="text-center">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Row className="d-flex justify-content-center">
                    <Col xl={3} lg={4} md={5} sm={6} xs={8}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <div style={{ position: "relative" }} className="w-100">
                        <button
                          className="btn uniform-btn btn-lg w-100 border-0"
                          onClick={handleUploadClick}
                          style={{
                            visibility: isImageSelected ? "hidden" : "visible",
                          }}
                        >
                          <FontAwesomeIcon icon={faCamera} className="me-2" />
                          Upload Your Room
                        </button>
                        {isImageSelected && (
                          <div>
                            <span>
                              <ProgressBar
                                className="btn btn-lg uniform-btn rounded-pill w-100 p-0"
                                now={uploadProgress as number}
                                variant="determinate"
                                style={{
                                  height: "50px",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  borderRadius:"#007bff",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <b className="mt-4">Uploading...{uploadProgress}%</b>
                              </div>
                            </span>
                            <button
                              className="btn rounded-circle"
                              style={{
                                position: "absolute",
                                top: "24px",
                                right: "-25px",
                                transform: "translate(50%, -50%)",
                                zIndex: "10",
                                height: "45px",
                                width : "45px",
                                background: "#E82646",
                              }}
                              onClick={handlecancelUpload}
                              title="Cancel Uploading"
                            >
                              <FontAwesomeIcon icon={faTimes} size="lg" className="cancel-uploading"/>
                            </button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="image-grid mt-4">
                {selectedImages?.map((item: any, index: number) => (
                  <Col
                    key={index}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    className="mb-3"
                  >
                    <div className="card">
                      <div className="card-body">
                        <img
                          className="card-img-top"
                          src={item?.image}
                          alt="Card image cap"
                        />
                        <hr />
                        <div className="justify-content-between d-flex">
                          <a
                            href="#"
                            className="btn uniform-btn btn-bg-uniform border-0"
                            onClick={() => setRoomQueryParams(item.id)}
                          >
                            {" "}
                            Select{" "}
                          </a>
                          <a
                            href="#"
                            className="btn uniform-delete-btn"
                            onClick={() => handleDeleteImageConfirmation(index)}
                          >
                            {" "}
                            Delete{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <Row className=" row mt-2 d-flex justify-content-center mb-2">
            <Col md={6} className="mt-2 d-flex justify-content-center ">
              <button
                className="btn btn-sm btn-block rounded-pill w-auto px-5 border-0"
                onClick={loadMoreImages}
                disabled={displayedImagesCount >= totalPage}
                style={{
                  color: "white",
                  backgroundColor:
                    displayedImagesCount >= totalPage ? "grey" : "#0d6efd",
                }}
              >
                Load More Images
              </button>
            </Col>
          </Row>
        </div>
      </BottomSheet>
      <Modal
        show={deleteConfirmation}
        onHide={() => setDeleteConfirmation(false)}
        dialogClassName="confirmation-modal"
        centered
      >
        <Modal.Body className="text-center d-flex flex-column">
          <h5>Confirm Deletion</h5>
          <p>Are you sure you want to delete this image?</p>
          <div className="border-0 mt-3 d-flex justify-content-around">
            <button
              className="btn btn-sm uniform-btn"
              style={{ width: "45%" }}
              onClick={() => setDeleteConfirmation(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm uniform-delete-btn"
              style={{ width: "45%" }}
              onClick={handleConfirmDelete}
            >
              <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faTrash} />
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangeRoom;
