import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

interface DecodedToken {
    token: string;
}

export function getToken(): string | null {
    const userDataString = Cookies.get("user");

    if (userDataString) {
        const userData = JSON.parse(userDataString);
        return userData.token;
    }

    return null; // Return null if the token is not found in cookies
}

export function getDecodedToken(): any | null {
    const token = getToken();

    if (token) {
        try {
            const decodedToken = jwtDecode(token) as DecodedToken;
            return decodedToken;
        } catch (error) {
            console.error("Error decoding token:", error);
            return null;
        }
    }

    return null; // Return null if the token is not found or cannot be decoded
}
