import React, { useState } from "react";
import { Container, Form, Image, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const SigninKiosk = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-99pr">
      <div className="row">
        <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
          <div className="signin-form-wrapper">
            <div className="text-center">
              <img
                src="/images/Spaceai_kiosk_logo.svg"
                className="d-inline-block align-top signin-logo mb-5"
                alt="Space AI Logo"
              />
            </div>
            <h1 className="mt-5 text-center">Sign In</h1>
            <Form>
              <Form.Group controlId="email" className="pt-2">
                <Form.Label className="fs-5 mb-0">Email</Form.Label>
                <Form.Control
                  className="form-control form-control-lg form-control-solid fs-5"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // required
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label className="fs-5 mb-0 mt-1">Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    className="form-control form-control-lg form-control-solid"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye-fill"></i>
                    ) : (
                      <i className="bi bi-eye-slash-fill"></i>
                    )}
                  </span>
                </div>
              </Form.Group>

              <div className="float-end mt-1 mb-3">
                <Link to="#" className="mt-2 text-center forgotpass">
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="mt-3 mb-2 mb-md-0 btn uniform-btn btn-sm py-2 border-0 w-100"
                disabled={loading}
                onClick={() => navigate("/kiosksapp")}
              >
                {loading ? (
                  <span className="loadingspinnerbtn">
                    <Spinner
                      as="span"
                      animation="border"
                      className="m-1"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  <span className="fs-5">Sign In</span>
                )}
              </button>
            </Form>
          </div>
        </div>
        <div className="col-md-6 col-12 d-none d-lg-flex justify-content-end bg-white">
          <Image
            src="/images/console_sign_in_image.jpg"
            className="signin-image"
            alt="Placeholder Image"
          />
        </div>
      </div>
    </div>
  );
};

export default SigninKiosk;
